import React from 'react';
import { TitleAndIngessImageCtaHero } from './TitleAndIngressImageCtaHero';
import { TitleAndIngressHero } from './TitleAndIngressHero';
import { TitleAndWideImageHero } from './TitleAndWideImageHero';
import { ThemeHeroBlockProps } from './types';

export const ThemeHeroBlock = ({ block, breadCrumbs }: ThemeHeroBlockProps) => {
  const { type, _type, ...restBlock } = block;
  const heroType = type || _type;
  switch (heroType) {
    case 'heroWithTitleIngressSmallImageAndCta':
    case 'hero1': // Kept to support already existing topic pages, consider migrating to new names
      return (
        <TitleAndIngessImageCtaHero
          hero={{ heroVariant: 'heroWithTitleIngressSmallImageAndCta', ...block }}
          breadCrumbs={breadCrumbs}
        />
      );
    case 'heroWithTitleAndIngress':
    case 'hero2': // Kept to support already existing topic pages, consider migrating to new names
      return (
        <TitleAndIngressHero
          hero={{ heroVariant: 'heroWithTitleAndIngress', ...restBlock }}
          breadCrumbs={breadCrumbs}
        />
      );
    case 'heroWithTitleAndWideImage':
    case 'hero3': // Kept to support already existing topic pages, consider migrating to new names
      return (
        <TitleAndWideImageHero
          hero={{ heroVariant: 'heroWithTitleAndWideImage', ...restBlock }}
          breadCrumbs={breadCrumbs}
        />
      );
    default:
      return null;
  }
};
